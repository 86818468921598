import image1 from "../../assets/t-image1.jpg";
import image2 from "../../assets/t-image2.jpg";
import image3 from "../../assets/t-image3.jpg";

export const TestData = [
  {
    id : 1,
    image: image1,
    review:
      "“CLEAN SPACE, UPDATED EQUIPMENT, EASY PARKING, NICE LOCKER ROOM, GREAT STAFF.”",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    id : 2,
    image: image2,
    review: '“CONVENIENTLY LOCATED GOOD AESTHETICS PLENTY OF ROOMS ― DOWNSTAIRS, WOMEN’S ROOM AND CLASSROOM SPACE LOW KEY” ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    id : 3,
    image : image3,
    review:'“HIGH QUALITY, DIVERSE GROUP CLASSES; EQUIPMENTS IN GOOD CONDITION; PEOPLE ARE NICE TO ONE ANOTHER LIKE IN A FRIENDLY COMMUNITY ”',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];